function useCreateGiftCertificate() {
  function createCertificate(data) {
    delete data.checkbox
    fetch(`${process.env.NETLIFY_API_BASE_URL}?endpoint=gift_certificates`, {
      method: 'POST',
      credentials: 'same-origin',
      mode: 'same-origin',
      body: JSON.stringify(data),
    })
      .then(async (res) => ({ response: await res.json(), status: res.status }))
      .then(({ response }) => console.log(response))
      .catch((error) => console.error(error))
  }
  return { createCertificate }
}

export default useCreateGiftCertificate
