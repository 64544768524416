import React from "react";
import PropTypes from "prop-types";
import ErrorMessage from "./ErrorMessage";

function TextField(
  {
    id,
    name,
    value,
    placeholder,
    type = "text",
    size,
    onChange,
    onBlur,
    width,
    label,
    labelFor,
    required,
    error,
    touched,
    ...props
  },
  ref
) {
  return (
    <>
      {label && (
        <label className="form-label" htmlFor={labelFor}>
          {label}
          {required && <small>REQUIRED</small>}
        </label>
      )}
      <input
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        type={type}
        onChange={onChange}
        ref={ref}
        style={{ width: `100%` }}
        onBlur={onBlur}
        className={"form-input"}
        {...props}
      />

      {error && touched && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}

TextField.prototype = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(["text", "password"]),
  size: PropTypes.oneOf(["sm", "md"]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  width: PropTypes.number,
  label: PropTypes.string,
  labelFor: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.string,
};

TextField.displayName = "TextField";

export default React.forwardRef(TextField);
