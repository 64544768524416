import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const CertificatePageNavigation = ({ path }) => {
  return (
    <nav className="account-nav-list page-block">
      <ul className="list-style-none flex align-center">
        <li className={path === "gift-certificate" ? "active" : null}>
          <Link to="/orders">Purchase Gift Certificate </Link>
        </li>
      </ul>
    </nav>
  );
};

CertificatePageNavigation.propTypes = {
  path: PropTypes.string,
};

export default CertificatePageNavigation;
